var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "JsModal",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: { title: "新建课程" },
      on: { closeHandler: _vm.doClose },
    },
    [
      _vm.coursePackage
        ? _c(
            "div",
            {
              staticClass:
                "c_rl_course_packege_index d-flex flex-column bbox_wrapper",
            },
            [
              _c("MainWrapper", {
                ref: "main",
                staticClass: "main_wrapper",
                on: { automaticDetection: _vm.automaticDetection },
              }),
              _c(
                "div",
                {
                  staticClass:
                    "fun_group flex-shrink-0 d-flex align-center justify-space-between",
                },
                [
                  _c("div", { staticClass: "d-flex align-center" }, [
                    _c(
                      "div",
                      {
                        staticClass: "btn save",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.doSave.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("\n          保存草稿\n        ")]
                    ),
                    0
                      ? _c(
                          "div",
                          {
                            staticClass: "btn audit btn_dark",
                            class: {
                              unable: !_vm.auditable,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.doAudit.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("\n          提交审核\n        ")]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "btn audit btn_dark",
                        class: {
                          unable: !_vm.auditable,
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.toggleMenu.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v("\n          提交审核\n          "),
                        _vm.isMenuVisible
                          ? _c("div", { staticClass: "sub-menu" }, [
                              _vm.coursePackage.teachingType === 1
                                ? _c(
                                    "div",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectAuditOption(3)
                                        },
                                      },
                                    },
                                    [_vm._v("资源库审核")]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectAuditOption(2)
                                    },
                                  },
                                },
                                [_vm._v("专人审核")]
                              ),
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectAuditOption(1)
                                    },
                                  },
                                },
                                [_vm._v("无需审核")]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                  _vm.coursePackage.checkstatus !== 1 &&
                  _vm.coursePackage.checkstatus !== 4
                    ? _c(
                        "div",
                        {
                          staticClass: "check_status_group d-flex align-center",
                          class: {
                            [_vm.statusType]: true,
                          },
                        },
                        [
                          _c("div", { staticClass: "icon" }),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.statusText) +
                                "\n        "
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.coursePackage.checkstatus === 4
                    ? _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "top",
                            width: "200",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "c_rl_course_package_edit_reason_group",
                            },
                            [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("\n            未通过原因:\n          "),
                              ]),
                              _c("div", { staticClass: "reason" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.coursePackage.reason) +
                                    "\n          "
                                ),
                              ]),
                              _c("div", { staticClass: "check_user" }, [
                                _vm._v(
                                  "\n            审核人: " +
                                    _vm._s(_vm.coursePackage.checkUserName) +
                                    "\n          "
                                ),
                              ]),
                            ]
                          ),
                          _c("template", { slot: "reference" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "check_status_group d-flex align-center",
                                class: {
                                  [_vm.statusType]: true,
                                },
                              },
                              [
                                _c("div", { staticClass: "icon" }),
                                _c("div", { staticClass: "text" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.statusText) +
                                      "\n            "
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }