<script>
import request from '@/api/request'
import UserInfo from '@/models/common/user_info'
import { mapState } from 'vuex'
import store from '@/store'

export default {
  data () {
    return {
      phone: '',
      loading: false,
      auditUser: undefined,
      // selectUser: false,
    }
  },
  computed: {
    ...mapState({
      task: state => state.task,
      // checkMethod: state => state.task.checkMethod,
      submitCheckMethod: state => state.task.submitCheckMethod,
      coursePackagePersonAuditMethod: store => store.course_package.personAuditMethod,
      coursePackage: state => state.course_package.operationData,
    }),
  },
  methods: {
    filterNonNumeric () {
      // 过滤非数字字符
      this.phone = this.phone.replace(/\D/g, '')
      // 检测如何输入的数字===11位 进行搜索
      if (this.phone.length === 11) {
        this.doSearch({
          keyCode: 13
        })
      }
    },
    clearAuditUser () {
      this.auditUser = undefined
      // this.selectUser = false
    },
    async doSearch (e) {
      if (this.loading) {
        return
      }
      // 校验是否是英文的回车
      if (e.keyCode === 13) {
        if (e.shiftKey) {
          return
        }
        // 校验手机号是否正确
        const phoneRegex = /^1[3-9]\d{9}$/;
        if (!phoneRegex.test(this.phone)) {
          this.$notice({
            desc: '请输入正确的手机号码',
            type: 'error'
          })
          // this.phone = '';
          return
        }
        this.loading = true
        // 发送请求
        const res = await request.request(
          '/project/searchUser',
          {
            param: this.phone
          }
        )
        this.loading = false
        if (res.message === 'success') {
          if (res.contents && res.contents.list && res.contents.list.length) {
            const userInfo = new UserInfo(res.contents.list[0])
            this.auditUser = userInfo
            // this.selectUser = true
            // this.$modal.show('globalSelectAuditUser')
          }
        } else {
          this.auditUser = undefined
        }
      }
    },
    clearPhone () {
      this.phone = ''
      this.clearAuditUser()
    },
    // 关闭
    doClose () {
      this.$modal.hide('globalSelectAuditUser')
    },
    // 确定
    async doSure () {
      const self = this.$tools.getCookie('user')
      const selfId = this.$tools.getUserId()
      const {
        auditUser,
      } = this
      if (Number(selfId) === Number(auditUser.userId)) {
        this.$notice({
          desc: '审核人不能是自己',
          type: 'error'
        })
        return
      }
      if (5494 === Number(auditUser.userId)) {
        this.$notice({
          desc: '审核人不能是小妙',
          type: 'error'
        })
        return
      }
      console.log(selfId)
      console.log(self)
      console.log(this.submitCheckMethod)
      if (this.submitCheckMethod) {
        this.task.task.checkType = 2
        this.task.task.checkUserId = auditUser.userId
        console.log(this.task);
        await this.submitCheckMethod()
        this.$modal.hide('globalSelectAuditUser')
      }
      if (this.coursePackagePersonAuditMethod) {
        if (this.coursePackage) {
          this.coursePackage.checkType = 2
          // this.coursePackage.checkstatus = 2
          this.coursePackage.checkuserid = auditUser.userId
          await this.coursePackagePersonAuditMethod()
          this.$modal.hide('globalSelectAuditUser')
        }
      }
    },
    // 组件销毁时清除关键数据
    clearData () {
      store.commit('setTaskModuleSubmitCheckMethod', undefined)
    }
  },
  // 组件销毁时清除关键数据
  beforeDestroy () {

  }
}
</script>

<template>
<div
  v-loading="loading"
  class="c_audit_select_audit_user"
>
  <div
    class="title_wrapper"
  >
    <div
      class="title"
    >
      添加审核人
    </div>
    <div
      class="close cp"
      @click="doClose"
    ></div>
  </div>
  <div
    class="search_wrapper d-flex align-center"
  >
    <div
      class="icon flex-shrink-0"
    ></div>
    <input
      type="text"
      class="search"
      placeholder="请输入电话号码搜索平台用户"
      v-model="phone"
      maxlength="11"
      @input="filterNonNumeric"
      @keydown.enter="doSearch"
    >
    <div
      v-show="phone"
      class="clear flex-shrink-0 cp"
      @click="clearPhone"
    ></div>
  </div>
  <div
    class="user_wrapper d-flex align-center justify-center"
  >
    <div
      v-if="!auditUser"
      class="no_one"
    ></div>
    <div
      v-if="auditUser"
      class="user_group d-flex align-center justify-space-between unsel"
    >
      <div
        class="avatar flex-shrink-0"
        :style="{
          backgroundImage: `url(${ $avatar_url }${ auditUser.thumbnail })`
        }"
      ></div>
      <div
        class="info_group d-flex align-start justify-space-between flex-column"
      >
        <div
          class="name"
        >
          {{ auditUser.userName }}
        </div>
        <div
          class="phone"
        >
          {{ auditUser.loginName }}
        </div>
      </div>
      <div
        class="slecte_btn flex-shrink-0 on"
      ></div>
    </div>
  </div>
  <div
    class="fun_wrapper d-flex align-center justify-center"
  >
    <div
      class="btn btn_dark"
      @click="doSure"
    >
      确定
    </div>
    <div
      class="btn btn_light"
      @click="doClose"
    >
      取消
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.c_audit_select_audit_user {
  .title_wrapper {
    width: 100%;
    position: relative;
    font-size: 18px;
    font-weight: 600;
    color: #333;
    padding: 10px 0;
    text-align: center;
    margin-bottom: 20px;
    .close {
      @include background(12px 12px);
      position: absolute;
      right: 10px;
      top: 50%;
      width: 20px;
      height: 20px;
      transform: translateY(-50%);
      background-image: url('~@/assets/images/common/close.png');
    }
  }
  .search_wrapper {
    width: 300px;
    margin: 0 auto;
    background-color: #f5f5f5;
    margin-bottom: 20px;
    border-radius: 4px;
    position: relative;
    .icon {
      @include background(16px 16px);
      width: 20px;
      height: 20px;
      background-image: url('~@/assets/images/common/search_icon.png');
      margin-right: 10px;
      margin-left: 20px;
    }
    .search {
      width: 100%;
      border: none;
      font-size: 14px;
      color: #333;
      line-height: 40px;
      padding: {
        right: 40px;
      }
      outline: none;
    }
    .clear {
      @include background(14px 14px);
      width: 20px;
      height: 20px;
      background-image: url('~@/assets/images/common/clear_icon.png');
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .user_wrapper {
    width: 300px;
    margin: 10px auto 20px;
    height: 140px;
    .no_one {
      background-size: contain;
      width: 130px;
      height: 100%;
      background-image: url('~@/assets/images/common/default.png');
      background-repeat: no-repeat;
      background-position: center;
    }
    .user_group {
      width: 100%;
      height: 100%;
      .avatar {
        @include background;
        width: 60px;
        height: 60px;
        border-radius: 2px;
        background-color: #f5f5f5;
        margin-right: 10px;
        background-size: cover;
      }
      .info_group {
        width: 100%;
        height: 50px;
        .name {
          font-size: 14px;
          color: #333;
          font-weight: 600;
          margin-bottom: 10px;
        }
        .phone {
          font-size: 12px;
          color: #999;
        }
      }
      .slecte_btn {
        margin-left: 10px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid #ccc;
        &.on {
          background-image: url(~@/assets/images/common/selected.png);
        }
      }
    }
  }
  .fun_wrapper {
    width: 100%;
    padding-bottom: 20px;
    .btn {
      width: 100px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 4px;
      font-size: 14px;
      margin: 0 10px;
    }
  }
}
</style>
