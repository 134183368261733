var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "c_audit_select_audit_user",
    },
    [
      _c("div", { staticClass: "title_wrapper" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("\n      添加审核人\n    "),
        ]),
        _c("div", { staticClass: "close cp", on: { click: _vm.doClose } }),
      ]),
      _c("div", { staticClass: "search_wrapper d-flex align-center" }, [
        _c("div", { staticClass: "icon flex-shrink-0" }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.phone,
              expression: "phone",
            },
          ],
          staticClass: "search",
          attrs: {
            type: "text",
            placeholder: "请输入电话号码搜索平台用户",
            maxlength: "11",
          },
          domProps: { value: _vm.phone },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.phone = $event.target.value
              },
              _vm.filterNonNumeric,
            ],
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.doSearch.apply(null, arguments)
            },
          },
        }),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.phone,
              expression: "phone",
            },
          ],
          staticClass: "clear flex-shrink-0 cp",
          on: { click: _vm.clearPhone },
        }),
      ]),
      _c(
        "div",
        { staticClass: "user_wrapper d-flex align-center justify-center" },
        [
          !_vm.auditUser ? _c("div", { staticClass: "no_one" }) : _vm._e(),
          _vm.auditUser
            ? _c(
                "div",
                {
                  staticClass:
                    "user_group d-flex align-center justify-space-between unsel",
                },
                [
                  _c("div", {
                    staticClass: "avatar flex-shrink-0",
                    style: {
                      backgroundImage: `url(${_vm.$avatar_url}${_vm.auditUser.thumbnail})`,
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "info_group d-flex align-start justify-space-between flex-column",
                    },
                    [
                      _c("div", { staticClass: "name" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.auditUser.userName) +
                            "\n        "
                        ),
                      ]),
                      _c("div", { staticClass: "phone" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.auditUser.loginName) +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "slecte_btn flex-shrink-0 on" }),
                ]
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { staticClass: "fun_wrapper d-flex align-center justify-center" },
        [
          _c(
            "div",
            { staticClass: "btn btn_dark", on: { click: _vm.doSure } },
            [_vm._v("\n      确定\n    ")]
          ),
          _c(
            "div",
            { staticClass: "btn btn_light", on: { click: _vm.doClose } },
            [_vm._v("\n      取消\n    ")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }