<script>
export default {
  name: "index"
}
</script>

<template>
  <div
    class="c_task_teaching_resources_edit_index"
  >

  </div>
</template>

<style scoped lang="scss">

</style>
