<script>
import qrcode from 'qrcode'
import { mapState } from 'vuex'
import files from '@/utils/files'
import html2canvas from 'html2canvas';
export default {
  data () {
    return {
      qrcodeBase64Url: '',
    //  7天后的时间
      sevenDaysTime: '',
      sevenDaysTimestamp: '',
      avatarBase64Url: '',
    }
  },
  computed: {
    ...mapState({
      chatDetail: state => state.chat_detail,
    }),
  },
  mounted () {
    this.init()
  },
  methods: {
    init() {
      this.generateQrCode()
      this.getSevenDaysTimestamp()
      // this.generateAvatarToBase64()
    },
    doClose () {
      this.$modal.hide('globalGroupQrCode')
    },
    async generateQrCode () {
      const { VUE_APP_H5 } = process.env
      // 将sevenDaysTimestamp拼接'exire', 然后将拼接后的字符串进行base64加密
      const expire = btoa(`${this.sevenDaysTimestamp}exire`)
      // 将chatId 拼接chat, 然后将拼接后的字符串进行base64加密
      const chat = btoa(`${this.chatDetail.chat.chatId}chat`)
      // 将拼接的字符串和VUE_APP_H5组合为一个完整的url
      const url = `${VUE_APP_H5}join_group?chat=${chat}&expire=${expire}`
      const qrurl = await qrcode.toDataURL(url, {
        errorCorrectionLevel: 'H',
        type: 'image/jpeg',
        quality: 0.3,
        margin: 2,
        modules: '',
        version: 8,
        maskPattern: 1,
        segments: '',
        width: 200,
      })
      this.qrcodeBase64Url = qrurl
    },
    // 生成当前日期之后7天的时间戳, 截止到7天后的23:59:59

    getSevenDaysTimestamp () {
      const currentDate = new Date()
      const sevenDaysLater = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000)
      const year = sevenDaysLater.getFullYear()
      const month = (sevenDaysLater.getMonth() + 1).toString().padStart(2, '0')
      const day = sevenDaysLater.getDate().toString().padStart(2, '0')
      const endTime = `${year}-${month}-${day} 23:59:59`
      const timestamp = new Date(endTime).getTime()
      this.sevenDaysTime = this.$moment(timestamp).format('MM月DD日')
      this.sevenDaysTimestamp = timestamp
    },

    async generateAvatarToBase64 () {
      // const { chatDetail } = this
      // if (!chatDetail.chat.photo) {
      //   return
      // }
      // console.log(133)
      // files.urlToBase64(`${this.$avatar_url}${chatDetail.chat.photo}`, (base64) => {
      //   console.log('11231')
      //   this.avatarBase64Url = base64
      //   console.log(base64)
      // })
      const { chatDetail } = this
      if (!chatDetail.chat.photo) {
        return
      }
      const imgUrl = `/img-icon/${chatDetail.chat.photo}`
      try {
        const base64 = await this.convertImageToBase64(imgUrl)
        this.avatarBase64Url = base64
      } catch (error) {
        console.error('图片转换失败:', error)
      }
    },
    convertImageToBase64(url) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.crossOrigin = 'anonymous'
        img.onload = () => {
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          canvas.width = img.width
          canvas.height = img.height
          ctx.drawImage(img, 0, 0)
          const base64 = canvas.toDataURL('image/png')
          resolve(base64)
        }
        img.onerror = () => {
          reject(new Error('图片加载失败'))
        }
        img.src = url
      })
    },

    async doSave () {
      // 保存时将按钮opacity设置为0, 将title_wrapper的opacity设置为0
      const button = document.querySelector('.v_fun_task_group_qr_code .fun_wrapper')
      button.style.opacity = 0
      const title_wrapper = document.querySelector(' .v_fun_task_group_qr_code .title_wrapper')
      title_wrapper.style.opacity = 0
      // const content_wrapper = document.querySelector('.content_wrapper')
      // content_wrapper.style.opacity = 0
      files.downloadElement({
        querySelector: '#group_qr_code',
        name: `分享${ this.chatDetail.chat.chatName }-${this.sevenDaysTime}`
      })
      button.style.opacity = 1
      title_wrapper.style.opacity = 1

      // content_wrapper.style.opacity = 1
    }


  }
}

</script>

<template>
<div
  id="group_qr_code"
  class="v_fun_task_group_qr_code bbox_wrapper"
>
  <div
    class="title_wrapper"
  >
    <div
      class="title"
    >
      二维码
    </div>
    <div
      class="close cp"
      @click="doClose"
    ></div>
  </div>
  <div
    class="content_wrapper"
  >
    <div

    >
      <div
        class="info_wrapper d-flex align-stretch"
      >
        <!-- 如果是测试模式应该是/img-icon/前缀 如果是发布环境 应该是 $avatar_url-->
        <div
          v-if="1"
          class="avatar flex-shrink-0"
          :style="{
            backgroundImage: `url(${ $avatar_url }${chatDetail.chat.photo}?v=${ Date.now() })`
          }"
        ></div>
        <img
          v-if="0"
          :src="`${ $avatar_url }${chatDetail.chat.photo}`"
          alt=""
          class="avatar flex-shrink-0"
        >
        <div
          class="info_group d-flex justify-space-between align-start flex-column"
        >
          <div
            class="name"
          >
            {{ chatDetail.chat.chatName }}
          </div>
          <div
            class="hint"
          >
            妙行群聊
          </div>
        </div>
      </div>
      <div
        class="qrcode"
        :style="{
          backgroundImage: `url(${qrcodeBase64Url})`
        }"
      ></div>
    </div>
    <div
      class="qrcode_info"
    >
      该二维码7天内（{{ sevenDaysTime }}前）有效
    </div>
    <div
      class="fun_wrapper d-flex align-center justify-center"
    >
      <div
        v-if="0"
        class="btn btn_light"
      >
        分享
      </div>
      <div
        class="btn btn_dark"
        @click="doSave"
      >
        保存图片
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.v_fun_task_group_qr_code{
  background: #FFFFFF;
  border-radius: 10px;
  .title_wrapper{
    width: 400px;
    height: 60px;
    background: #F8F8F8;
    border-radius: 10px 10px 0 0;
    position: relative;
    text-align: center;
    .title {
      font-weight: bold;
      font-size: 16px;
      color: #333333;
      line-height: 60px;
      text-align: center;
      font-style: normal;
    }
    .close {
      @include background(12px 12px);
      position: absolute;
      z-index: 2;
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/common/close.png);
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .content_wrapper{
    padding: 40px;
    .info_wrapper{
      width: 100%;
      overflow: auto;
      margin-bottom: 35px;
      .avatar {
        @include background(50px 50px);
        width: 50px;
        height: 50px;
        margin-right: 10px;
        border-radius: 2px;
      }
      .info_group {
        width: 100%;
        overflow: auto;
        .name {
          @include single_line_intercept;
          font-weight: bold;
          font-size: 16px;
          color: #333333;
          line-height: 21px;
        }
        .hint {
          font-size: 14px;
          color: #333333;
          line-height: 19px;
          font-style: normal;
        }
      }
    }
    .qrcode {
      width: 200px;
      height: 200px;
      margin: 0 auto;
      background: #F8F8F8;
      margin-bottom: 20px;
    }
    .qrcode_info {
      font-size: 14px;
      color: #999999;
      line-height: 19px;
      text-align: center;
      font-style: normal;
      margin-bottom: 40px;
    }
    .fun_wrapper{
      .btn {
        width: 120px;
        line-height: 40px;
        border-radius: 4px;
        margin: 0 5px;
        text-align: center;
      }
    }
  }
}
</style>
