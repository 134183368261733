<script>
import E from 'wangeditor'
import oss from '@/api/modules/oss'
import { mapState } from 'vuex'
import tools from '@/api/tools'
export default {
  data () {
    return {
      teachTypeList: [
        {
          value: 1,
          label: '标准课'
        },
        {
          value: 2,
          label: '实践课'
        },
        {
          value: 3,
          label: '专业群课'
        },
        {
          value: 4,
          label: '培训课'
        },
        {
          value: 5,
          label: '平台使用教程'
        },
        {
          value: 6,
          label: '其他'
        },
      ],
      type: '',
      videofile: '',
      docfile: '',
      webpic: '',
      content: '',
      title: '',
      loadingvideofile: false,
      loadingdocfile: false,
      loadingwebpic: false,
    }
  },
  watch: {
    type: function () {
      this.automaticDetection()
    },
    title: function () {
      this.automaticDetection()
    },
    content: function () {
      this.automaticDetection()
    },
  },
  computed: {
    ...mapState({
      // coursePackage: state => state.course_package.data,
      coursePackage: state => state.course_package.operationData,
    }),
    docType () {
      const { docfile } = this
      if (docfile) {
         return tools.getExtName(docfile)
      }
      return ''
    },
    notEditable () {
      const { coursePackage } = this
      if (coursePackage && Number(coursePackage.checkstatus) === 2) {
        return true
      }
      return false
    }
  },
  mounted () {
    this.init()
    this.$nextTick(function() {
      var editor = new E(this.$refs.editor)

      // 自定义菜单配置
      editor.config.menus = [
        'head',  // 标题
        'bold',  // 粗体
        'fontSize',  // 字号
        'fontName',  // 字体
        'italic',  // 斜体
        'underline',  // 下划线
        // 'strikeThrough',  // 删除线
        'foreColor',  // 文字颜色
        'backColor',  // 背景颜色
        // 'link',  // 插入链接
        'list',  // 列表
        'justify',  // 对齐方式
        // 'quote',  // 引用
        // 'emoticon',  // 表情
        'image',  // 插入图片
        // 'table',  // 表格
        // 'video',  // 插入视频
        // 'code',  // 插入代码
        // 'undo',  // 撤销
        // 'redo'  // 重复
      ]
      editor.config.uploadFileName = 'Filedata'
      // 限制一次最多上传 5 张图片
      editor.config.uploadImgMaxLength = 1
      // 配置服务器端地址
      editor.config.uploadImgServer = `https://${this.$domain_name}/project/uploadwangeditor`
      editor.config.uploadImgHeaders = {
        // "Content-Type": "multipart/form-data;",
        "loginUserCode": JSON.stringify(this.$tools.getCookie('loginUserCode')),
      }
      editor.create()
      editor.txt.html(this.content)
      // this.content = this.content
      setTimeout(() => {

        editor.config.onchange = (html) => {
          // console.log('编辑器修改了')
          // console.log(html)
          this.content = html
        }
      }, 200)
      if (this.notEditable) {
      // 如果是审核状态, 关闭编辑权限
        editor.disable()
      }
    })
  },
  methods: {
    automaticDetection () {
      this.$emit('automaticDetection')
    },
    init () {
      // console.log(this.coursePackage, 'info')
      if (this.coursePackage) {
        const {
          type,
          videofile,
          docfile,
          webpic,
          content,
          title,
        } = this.coursePackage
        this.type = type
        this.videofile = videofile
        this.docfile = docfile
        this.webpic = webpic
        this.content = content
        this.title = title
      }
    },
    touchUpload (name) {
      // console.log(name, this.notEditable)
      if (this.notEditable) {
        return
      }
      if (this[`loading${ name }`]) {
        return
      }
      if (this.$refs[name]) {
        this.$refs[name].click()
      }
    },
    async videoChange () {
      const file = this.$refs.videofile.files[0]
      this.loadingvideofile = true
      const res = await this.uploadFile({
        file,
        fieldName: 'videofile'
      })
      this.loadingvideofile = false
      this.videofile = res
    },
    async docChange () {
      const file = this.$refs.docfile.files[0]
      this.loadingdocfile = true
      const res = await this.uploadFile({
        file,
        fieldName: 'docfile'
      })
      this.loadingdocfile = false
      this.docfile = res
    },
    async picChange () {
      const file = this.$refs.webpic.files[0]
      // console.log(file)
      this.loadingwebpic = true
      const res = await this.uploadFile({
        file,
        fieldName: 'webpic'
      })
      this.loadingwebpic = false
      this.webpic = res
    },
    async uploadFile (params) {
      // console.log(params)
      const {
        file,
      } = params
      if (!file) {
        return
      }

      const res = await oss.uploadFile({
         file
       })
      return res
    }
  }
}
</script>

<template>
  <div
    class="course_info bbox_wrapper"
  >
    <!-- 标题 -->
    <div
      class="line_group flex-shrink-0"
    >
      <div
        class="name_group"
      >
        <div
          class="symbol"
        >
          *
        </div>
        <div
          class="name"
        >
          标题:
        </div>
      </div>
      <div
        class="info_group"
      >
        <input
          v-model="title"
          type="text"
          class="title"
          placeholder="请填写标题"
          :disabled="notEditable"
        >
      </div>
    </div>
    <!-- 简介 -->
    <div
      class="line_group"
    >
      <div
        class="name_group"
      >
        <div
          class="symbol"
        >
          *
        </div>
        <div
          class="name"
        >
          简介:
        </div>
      </div>
      <div
        class="info_group"
      >
        <div
          class="editor_wang_course_info"
          ref="editor"
        ></div>
      </div>
    </div>
    <!-- 教学类型 -->
    <div
      class="line_group"
    >
      <div
        class="name_group"
      >
        <div
          class="symbol"
        >
          *
        </div>
        <div
          class="name"
        >
          类型:
        </div>
      </div>
      <div
        class="info_group"
      >
        <el-select
          v-model="type"
          class="un_sel select_teach_type_group"
          placeholder="请选择教学类型"
          size="small"
          :disabled="notEditable"
        >
          <el-option
            v-for="item in teachTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <!--  上传概述 -->
    <div
      class="line_group"
    >
      <div
        class="name_group"
      >
        <div
          class="symbol"
        ></div>
        <div
          class="name"
        >
          概述:
        </div>
      </div>
      <div
        class="info_group"
      >
        <div
          class="upload_group d-flex"
        >
          <div
            class="btn_group d-flex"
            @click.stop="touchUpload('videofile')"
          >
            <div
              class="icon video"
            ></div>
            <div
              class="text"
            >
              {{ videofile ? '替换概述' : '上传概述' }}
            </div>
          </div>
          <div
            class="hide"
          >
            *仅支持上传视频类文档
          </div>
          <input
            type="file"
            style="display: none"
            accept="video/*"
            ref="videofile"
            @change="videoChange"
          >
        </div>
        <div
          v-show="loadingvideofile || videofile"
          v-loading="loadingvideofile"
          class="file_group"
        >
          <div
            class="file_info d-flex"
          >
            <div
              class="icon video"
            ></div>
            <div
              class="filename"
            >
              {{ loadingvideofile ? '上传中' : videofile }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  上传标准 -->
    <div
      class="line_group"
    >
      <div
        class="name_group"
      >
        <div
          class="symbol"
        ></div>
        <div
          class="name"
        >
          标准:
        </div>
      </div>
      <div
        class="info_group"
      >
        <div
          class="upload_group d-flex"
        >
          <div
            class="btn_group d-flex"
            @click.stop="touchUpload('docfile')"
          >
            <div
              class="icon file"
            ></div>
            <div
              class="text"
            >
              {{ docfile ? '替换标准' : '上传标准' }}
            </div>
          </div>
          <div
            class="hide"
          >
            *仅支持上传文本类文档
          </div>
          <input
            type="file"
            style="display: none"
            accept=".pdf,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ref="docfile"
            @change="docChange"
          >
        </div>
        <div
          v-show="loadingdocfile || docfile"
          v-loading="loadingdocfile"
          class="file_group"
        >
          <div
            class="file_info d-flex"
          >
            <div
              class="icon"
              :class="{
                [docType]: true
              }"
            ></div>
            <div
              class="filename"
            >
              {{ loadingdocfile ? '上传中' : docfile }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  展示图 -->
    <div
      class="line_group"
    >
      <div
        class="name_group"
      >
        <div
          class="symbol"
        ></div>
        <div
          class="name"
        >
          展示图:
        </div>
      </div>
      <div
        class="info_group"
      >
        <div
          class="upload_group d-flex align-start"
        >
          <div
            class="img_group un_sel cp"
          >
            <div
              v-if="!notEditable"
              class="text_group d-flex align-center justify-center"
              :class="{
                on: webpic
              }"
              @click.stop="touchUpload('webpic')"
            >
              <div
                class="text"
              >
                +&nbsp;点击{{ webpic ? '更换' : '上传' }}图片
              </div>
            </div>
            <div
              :style="{
                backgroundImage: `url(${ $file_url }${ webpic })`
              }"
              class="img"
            >
            </div>
          </div>
          <div
            class="hide"
          >
            *建议尺寸16:9
          </div>
          <input
            type="file"
            style="display: none"
            accept="image/*"
            ref="webpic"
            @change="picChange"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.course_info {
  @include scrollbar;
  width: 100%;
  height: 100%;
  overflow: auto;
  color: #333;
  padding: {
    top: 10px;
    left: 20px;
    right: 15px;
  }
  .line_group {
    display: flex;
    align-items: start;
    margin-bottom: 20px;
    width: 100%;
    .name_group {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      width: 66px;
      margin-right: 10px;
      .symbol {
        color: #E12F2F;
        width: 10px;
        text-align: center;
      }
      .name {
        line-height: 36px;
        font-size: 16px;
        color: #333333;
        text-align: left;
        font-style: normal;
      }
    }
    .info_group {
      width: 100%;
      input {
        @include placeholder(#bbb);
        font-size: 14px;
        padding: {
          left: 10px;
          right: 10px;
        }
      }
      .title {
        width: 100%;
        height: 36px;
        background: #FBFBFB;
        border-radius: 4px;
        border: 1px solid #E5E7EA;
      }
      .upload_group {
        .btn_group {
          cursor: pointer;
          height: 36px;
          background: #FFFFFF;
          border-radius: 4px;
          border: 1px solid #D7D9DD;
          padding: 0 10px;
          margin-right: 10px;
          &:hover {
            border-color: $main_blue;
            .text {
              color: $main_blue;
            }
            .icon.file {
              background-image: url(~@/assets/images/course_package/upload_file_on.png);
            }
            .icon.video {
              background-image: url(~@/assets/images/course_package/upload_video_on.png);
            }
          }
          .icon {
            @include background(16px 16px);
            width: 16px;
            height: 16px;
            margin-right: 5px;
            &.file {
              background-image: url(~@/assets/images/course_package/upload_file.png);
            }
            &.video {
              background-image: url(~@/assets/images/course_package/upload_video.png);
            }

          }
          .text {
            font-size: 14px;
            color: #333333;
            line-height: 19px;
            font-style: normal;
          }
        }
        .hide {
          font-size: 14px;
          color: #999999;
          line-height: 19px;
          text-align: left;
          font-style: normal;
        }
        .img_group {
          width: 340px;
          height: 191px;

          border-radius: 4px;
          border: 1px solid #E5E7EA;
          margin-right: 10px;
          position: relative;
          &:hover {
            .text_group {
              opacity: 1;
              &.on {
                opacity: 1;
              }
            }
          }
          .text_group {
            @include transition;
            width: 100%;
            height: 100%;
            font-size: 16px;
            color: #999999;
            line-height: 21px;
            font-style: normal;
            background-color: #FBFBFB;
            position: absolute;
            z-index: 2;
            opacity: 1;
            &.on {
              opacity: 0;
              color: #fff;
              background-color: rgba(0, 0, 0, 0.3);
            }
          }
          .img {
            width: 100%;
            height: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            position: relative;
            z-index: 1;
          }
        }
      }
      .file_group {
        padding-top: 10px;
        .file_info {
          .icon {
            @include background;
            width: 32px;
            height: 32px;
            margin-right: 10px;
            &.video {
              background-image: url(~@/assets/images/course_package/video.png);
            }
            &.excel {
              background-image: url(~@/assets/images/course_package/excel.png);
            }
            &.doc {
              background-image: url(~@/assets/images/course_package/doc.png);
            }
            &.mp4 {
              background-image: url(~@/assets/images/course_package/doc.png);
            }
            &.pdf {
              background-image: url(~@/assets/images/course_package/doc.png);
            }
            &.pic {
              background-image: url(~@/assets/images/course_package/doc.png);
            }
            &.ppt {
              background-image: url(~@/assets/images/course_package/doc.png);
            }
            &.txt {
              background-image: url(~@/assets/images/course_package/doc.png);
            }
            &.unknown {
              background-image: url(~@/assets/images/course_package/doc.png);
            }
            &.xls {
              background-image: url(~@/assets/images/course_package/doc.png);
            }
            &.zip {
              background-image: url(~@/assets/images/course_package/doc.png);
            }
          }
          .filename {
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            text-align: left;
            font-style: normal;
          }
        }
      }
    }

  }
}
</style>
<style>
.editor_wang_course_info {
  width: 100%;
  /*height: 300px;*/
}
.editor_wang_course_info .w-e-text-container {
  height: 270px!important;
}
.editor_wang_course_info .w-e-text {
  min-height: 270px!important;
}
.course_info .select_teach_type_group {
  width: 340px;
  height: 36px;
  background: #FBFBFB;
  border-radius: 4px;
  /*border: 1px solid #E5E7EA;*/
}
.course_info .el-select.select_teach_type_group .el-input.is-focus .el-input__inner {
  border: 1px solid #E5E7EA;
}
.course_info .el-select.select_teach_type_group .el-input .el-input__inner {
  width: 340px;
  height: 36px;
  background: #FBFBFB;
  border-radius: 4px;
  border: 1px solid #E5E7EA;
  color: #333;
  font-size: 14px;
}
</style>
