var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "JsModal",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: { title: "选择模板" },
      on: {
        closeHandler: function ($event) {
          return _vm.$modal.hide("globalCoursePackageChoiceTask")
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "c_rl_course_package_choice_task bbox_wrappe d-flex flex-column bbox_wrapper",
        },
        [
          _c("div", { staticClass: "main_wrapper d-flex flex-column" }, [
            _c(
              "div",
              {
                staticClass:
                  "select_group d-flex align-center justify-space-between",
              },
              [
                _c("div", { staticClass: "d-flex align-center" }, [
                  _c(
                    "div",
                    {
                      ref: "originGroup",
                      staticClass:
                        "origin_group d-flex align-center flex-shrink-0",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "group_1 flex-row justify-between flex-shrink-0",
                          on: { click: _vm.toggleDropdown },
                        },
                        [
                          _c("span", { staticClass: "text_2" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.typeName) +
                                "\n            "
                            ),
                          ]),
                          _c("img", {
                            staticClass: "thumbnail_2",
                            attrs: {
                              referrerpolicy: "no-referrer",
                              src: require("@/assets/images/course_package/select_course_task_search_type.png"),
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isDropdownVisible,
                              expression: "isDropdownVisible",
                            },
                          ],
                          staticClass: "dropdown-content",
                        },
                        _vm._l(_vm.options, function (option, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "dropdown-item",
                              on: {
                                click: function ($event) {
                                  return _vm.selectOption(option)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(option.name) +
                                  "\n              "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.keyword,
                            expression: "keyword",
                          },
                        ],
                        staticClass: "keyword",
                        attrs: { type: "text", placeholder: "搜索" },
                        domProps: { value: _vm.keyword },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.doSearch.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.keyword = $event.target.value
                          },
                        },
                      }),
                    ]
                  ),
                  _c("div", { staticClass: "type_group d-flex align-center" }, [
                    _c(
                      "div",
                      {
                        staticClass: "text",
                        class: {
                          active: _vm.listType === "package",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changeListType("package")
                          },
                        },
                      },
                      [_vm._v("\n              课程\n            ")]
                    ),
                    _c("div", { staticClass: "line" }),
                    _c(
                      "div",
                      {
                        staticClass: "text",
                        class: {
                          active: _vm.listType === "task",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changeListType("task")
                          },
                        },
                      },
                      [_vm._v("\n              教学模板\n            ")]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "create_task" }, [
                  _c(
                    "div",
                    {
                      staticClass: "create_btn",
                      on: { click: _vm.createTask },
                    },
                    [_vm._v("\n            + 新建任务\n          ")]
                  ),
                ]),
              ]
            ),
            _vm.currentPackage
              ? _c(
                  "div",
                  { staticClass: "navigation_group d-flex align-center" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "navigator",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.changeCurrentPackage(undefined)
                          },
                        },
                      },
                      [_vm._v("\n          全部\n        ")]
                    ),
                    _c("div", { staticClass: "symbol" }, [
                      _vm._v("\n          >\n        "),
                    ]),
                    _c("div", { staticClass: "navigator current" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.currentPackage.title) +
                          "\n        "
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "list_wrapper" },
              [
                _vm.showTaskOrigin
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "origin_group d-flex aling-center justify-start un_sel",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "origin",
                            class: {
                              on: _vm.taskOrigin === 1,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.changeTaskOrigin(1)
                              },
                            },
                          },
                          [_vm._v("\n            我创建的\n          ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "origin",
                            class: {
                              on: _vm.taskOrigin === 2,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.changeTaskOrigin(2)
                              },
                            },
                          },
                          [_vm._v("\n            我领取的\n          ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "origin",
                            class: {
                              on: _vm.taskOrigin === 3,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.changeTaskOrigin(3)
                              },
                            },
                          },
                          [_vm._v("\n            我收藏的\n          ")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.showPackageList
                  ? _c("PackageList", {
                      attrs: { "package-list": _vm.packageList },
                      on: { changeCurrentPackage: _vm.changeCurrentPackage },
                    })
                  : _vm._e(),
                _vm.showTaskList
                  ? _c("TaskList", {
                      attrs: {
                        "task-list": _vm.taskList,
                        "select-list": _vm.selectList,
                        "more-task": _vm.moreTask,
                        "show-selected": _vm.showSelected,
                        all: _vm.all,
                        "task-origin": _vm.taskOrigin,
                        "show-task-origin": _vm.showTaskOrigin,
                      },
                      on: {
                        loadMoreTaskList: _vm.loadMoreTaskList,
                        selectTask: _vm.selectTask,
                        touchAll: _vm.touchAll,
                        changeTaskOrigin: _vm.changeTaskOrigin,
                      },
                    })
                  : _vm._e(),
                _vm.showCollectPage
                  ? _c("CollectPage", {
                      attrs: {
                        switch_select_file: true,
                        show_menu: false,
                        "select-list": _vm.selectList,
                        "only-teach": true,
                        "show-resource-file": false,
                        "show-course": false,
                      },
                      on: { selectTask: _vm.selectTask },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass:
                "bottom_group flex-shrink-0 d-flex align-center justify-space-between",
            },
            [
              _c(
                "div",
                {
                  staticClass: "select_number_group d-flex align-center",
                  class: {
                    on: _vm.showSelected,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.changeShowSelected.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "number" }, [
                    _vm._v(
                      "\n          已选: " +
                        _vm._s(_vm.selectList.length) +
                        "个\n        "
                    ),
                  ]),
                  _c("div", { staticClass: "check_icon" }),
                ]
              ),
              _c("div", { staticClass: "fun_group d-flex align-center" }, [
                _c(
                  "div",
                  {
                    staticClass: "btn btn_light",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$modal.hide("globalCoursePackageChoiceTask")
                      },
                    },
                  },
                  [_vm._v("\n          取消\n        ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "btn btn_dark",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.doSure.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n          确定\n        ")]
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }