<script>
import PackageList from '@/components/resource_library/course_package/choice_task/package_list'
import TaskList from '@/components/resource_library/course_package/choice_task/task_list'
import request from '@/api/request'
import JsModal from '@/components/JsModal'
import CoursePackage from '@/models/cource_package/index'
import { listConvertToModel } from '@/models'
import TaskInfo from '@/models/task/task_info'
import { mapState } from 'vuex'
import CourseSection from '@/models/cource_package/course_section'
import store from '@/store'
import CollectPage from '@/components/Collect/Main'
export default {
  components: {
    PackageList,
    TaskList,
    JsModal,
    CollectPage,
  },
  data() {
    return {
      isDropdownVisible: false, // 控制下拉列表的显示
      options: [
        {
          name: '我的资源',
          value: 1
        },
        {
          name: '公共资源',
          value: 2
        },
        {
          name: '草稿箱',
          value: 3
        }
      ], // 下拉选项列表
      navigationList: [
        {
          name: '教学包名斯蒂芬妮',
          id: 0
        }
      ],
      listType: 'package', // or list
      typeName: '我的资源',
      type: 1,
      loading: false,
      packageList: [],
      currentPackage: undefined,
      taskList: [],
      moreTask: false,
      selectList: [],
      showSelected: false,
      keyword: '',
      taskOrigin: 1, // 1 我创建的, 2 我领取的, 3 我收藏的
      taskPage: 1,
    }
  },
  computed: {
    showCollectPage () {
      const {
        taskOrigin,
        type,
        listType,
      } = this
      return type === 1 && listType === 'task' && taskOrigin === 3
    },
    showTaskOrigin () {
      const {
        type,
        listType,
      } = this
      if (listType === 'package') {
        return false
      }
      return type === 1
    },
    ...mapState({
      courseSection: state => state.course_package.section,
      // coursePackage: state => state.course_package.data,
      coursePackage: state => state.course_package.operationData,
    }),
    showPackageList () {
      const { currentPackage, type, listType, showSelected } = this
      if (showSelected) {
        return false
      }
      if (listType === 'package') {
        if (currentPackage) {
          return false
        }
      }
      if (listType === 'task') {
        return false
      }
      return true
    },
    showTaskList () {
      const { currentPackage, type, listType, showSelected, taskOrigin } = this
      if (showSelected) {
        return true
      }
      if (listType === 'task') {
        if (taskOrigin === 3) {
          return false
        }
      }
      if (listType === 'package') {
        if (!currentPackage) {
          return false
        }
      }
      return true
    },
    all () {
      const { taskList, selectList } = this
      let all = true
      if (taskList && taskList.length) {
        if (selectList.length < taskList.length) {
          return false
        }
        for (let i in taskList) {
          const include = selectList.find((unit) => {
            return unit.id === taskList[i].id
          })
          if (!include) {
            all = false
            break
          }
        }
        return all
      }
      return false
    }
  },
  methods: {
    handleClickOutside (event) {
      if (this.$refs.originGroup &&!this.$refs.originGroup.contains(event.target)) {
        this.isDropdownVisible = false;
      }
    },
    changeTaskOrigin (taskOrigin) {
      if (this.taskOrigin === taskOrigin) {
        return
      }
      this.taskOrigin = taskOrigin
      this.$set(
        this,
        'currentPackage',
        undefined
      )
      this.clearPackageList()
      this.clearTaskList()
      this.getList()
    },
    async changeCurrentPackage (currentPackage) {
      this.$set(
        this,
        'currentPackage',
        currentPackage
      )
      if (currentPackage) {
        // 获取详情里面的列表
        await this.getPackageTask(currentPackage)
      }
    },
    async getPackageTask (currentPackage) {
      const res = await request.request(
        '/resource/queryCource',
        {
          courceId: currentPackage.id
        }
      )
      if (res.message === 'success') {
        const { PCource } = res.contents
        const {
          chapters
        } = PCource
        let list = []
        for (let i in chapters) {
          // console.log(i)
          list = [
            ...list,
            ...listConvertToModel({
              list: chapters[i].tasks,
              model: TaskInfo
            }),
            // ...chapters[i].tasks
          ]
        }
        // console.log(list)
        this.clearTaskList()
        this.taskList = [
          ...this.taskList,
          ...list
        ]
        // console.log(this.taskList)
      }
    },
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    selectOption(option) {
      // console.log('Selected:', option)
      // 隐藏下拉列表
      this.isDropdownVisible = false
      if (this.type === option.value) {
        return
      }
      // 这里可以添加选择后的处理逻辑，比如更新一个变量显示选中的值
      this.typeName = option.name
      this.type = option.value
      this.clearPackageList()
      this.clearTaskList()
      this.getList()
    },
    changeListType (type) {
      if (type === this.listType) {
        return
      }
      this.$set(
        this,
        'currentPackage',
        undefined
      )
      this.taskOrigin = 1
      this.listType = type
      this.clearPackageList()
      this.clearTaskList()
      this.getList()
    },
    async getPackageTaskList () {
      const res = await request.request(
        '/resource/myCreateCource'
      )
    },
    init () {
      if (this.courseSection) {
        if (this.courseSection.tasks && this.courseSection.tasks.length) {
          this.$set(
            this,
            'selectList',
            listConvertToModel({
              list: this.courseSection.tasks,
              model: TaskInfo
            })
          )
        }
      }
      // console.log(this.selectList)
      this.getList()
    },
    async getList () {
      const { taskOrigin, type } = this
      // console.log({
      //   type
      // })
      if (this.listType === 'package') {
        if (this.type === 1) {
          await this.getCoursePackageList()
          return
        }
        if (this.type === 2) {
          await this.getCoursePackageList()
          return
        }
        if (this.type === 3) {
          await this.getCoursePackageList()
          return
        }
        return
      }
      if (this.listType === 'task') {
        if (this.type === 1) {
          if (taskOrigin !== 3) {
            await this.getTaskList()
          }
          return
        }
        if (this.type === 2) {
          await this.getTaskList()
          return
        }
        if (this.type === 3) {
          await this.getTaskList()
          return
        }
      }
    },
    async getCoursePackageList () {
      if (this.loading) {
        return
      }
      const { type } = this
      const data = {
        checkstatus: 3,
        keyword: this.keyword.trim(),
      }
      let url = '/resource/myCreateCource'
      if (type === 3) {
        data.checkstatus = 5
      }
      if (type === 2) {
        url = '/resource/courceList'
      }
      this.loading = true
      const res = await request.request(
        url,
        data
      )
      this.loading = false
      const list = listConvertToModel({
        list: res.contents.page.result,
        model: CoursePackage
      })
      this.packageList = [
        ...this.packageList,
        ...list
      ]

    },
    async getTaskList () {
      console.log(this.loading)
      if (this.loading) {
        return
      }

      let startId = ''
      if (this.taskList && this.taskList.length) {
        startId = this.taskList[this.taskList.length - 1].id
      }
      let url = '/projectTask/taskByparamList'
      const data = {

      }
      const { type, taskOrigin } = this
      // console.log({
      //   type, taskOrigin
      // })
      if (type === 1) {
        data.startId = startId;
        data.showflag = '';
        data.checkStatus = 5;
        data.keyword = this.keyword.trim();
        if (taskOrigin === 1) {
          data.searchtype = 3;
          data.tagId = '';
        }
        if (taskOrigin === 2) {
          data.searchtype = 4;
        }
      }
      if (type === 2) {
        url = '/resource/teachFileTaskList'
        data.filetype = 99
        data.teachtype = 99
        data.pagesize = 20
        data.pn = this.taskPage ++
      }
      if (type === 3) {
        url = '/projectDraft/draftList'
        // 草稿箱
        data.searchtype = 5
        data.start = this.taskList.length
        data.taskType = 2
        data.checkstatus = 5
      }
      this.loading = true
      const res = await request.request(
        url,
        data
      )
      this.loading = false
      if (res.message === 'success') {
        let list = []
        if (type === 3) {
          list = listConvertToModel({
            list: res.contents.list,
            model: TaskInfo
          })
        } else if (type === 2 ) {
          list = listConvertToModel({
            list: res.contents.page.result,
            model: TaskInfo
          })

        }else {
          list = listConvertToModel({
            list: res.contents.taskList,
            model: TaskInfo
          })
        }

        this.taskList = [
          ...this.taskList,
          ...list
        ]
        if (list && list.length === 20) {
          this.moreTask = true
        } else {
          this.moreTask = false
        }
      }
    },
    clearPackageList () {
      this.$set(
        this,
        'packageList',
        []
      )

    },
    clearTaskList () {
      this.$set(
        this,
        'taskList',
        []
      )
      this.taskPage = 1
      this.moreTask = false
    },
    async loadMoreTaskList () {
      await this.getTaskList()
    },
    selectTask (taskInfo) {
      console.log(taskInfo)
      const index = this.selectList.findIndex((unit) => {
        return unit.id === taskInfo.id
      })
      if (index !== -1) {
        this.selectList.splice(index, 1)
      } else {
        this.selectList.push(taskInfo)
      }
    },
    touchAll () {
      const { all, taskList, selectList, showSelected } = this
      if (showSelected) {
        this.$set(
          this,
          'selectList',
          []
        )
      }
      if (all) {
        this.$set(
          this,
          'selectList',
          []
        )
      } else {
        for (let i in taskList) {
          const include = selectList.find((unit) => {
            return unit.id === taskList[i].id
          })
          if (!include) {
            selectList.push(taskList[i])
          }
        }
      }
    },
    doSure () {
      const { courseSection, coursePackage, selectList } = this
      if (courseSection) {
        courseSection.tasks = selectList
      } else {
        const newSection = new CourseSection()
        newSection.tasks = selectList
        // coursePackage.ca
        store.commit(
          'setCoursePackageSection',
          newSection
        )
        // this.$nextTick(function () {
          coursePackage.chapters.push(newSection)
        // })
      }
      this.$notice({
        desc: '操作成功',
        type: 'success'
      })
      store.commit('setCoursePackageCheckForUpdates', true)
      this.$modal.hide('globalCoursePackageChoiceTask')
    },
    changeShowSelected () {
      this.showSelected = !this.showSelected
      // console.log(this.showSelected)
    },
    doSearch (e) {
      if (e.keyCode === 229) {
        return
      }
      this.clearPackageList()
      this.clearTaskList()
      this.getList()
    },
    createTask () {
      this.$api.moduleTask.resetCallConfig();
      this.$nextTick(function(){
        this.$api.moduleTask.openTask({
          config:{
            create: true,
            select_target: true,
          }
        });
        // 关闭一系列按钮显示权限, 打开添加到任务按钮
        store.commit(
          'setTaskModuleTaskConfig',
          {
            showOrigin: false,
            // 显示颜色
            showColor: false,
            // 显示隐私模式
            showPrivacy: false,
            // 显示保存草稿
            showDraft: false,
            // 显示发布任务
            showPublish: false,
            // 显示最小化
            showMminimize: false,
            // 添加到任务
            showAddToCourse: true,
            // 显示教学提交审核
            showSubmitTeaching: false,
            // 显示教学草稿按钮
            showDraftTeaching: false,
            // 设置直接进入价格设置界面
            teachingPageStatue: 'price',
            // 设置进入教学区
            sideBarType: 'teach',
            // 添加到课程的回调
            addToCourseCallback: this.addToCourseCallback,
            // 前往维护
            showGoToMaintain: false,
          }
        )

      })
    },
    addToCourseCallback (task) {
      // 生成TaskInfo
      const taskInfo = new TaskInfo(task)
      // 将TaskInfo添加到当前课程
      this.selectTask(taskInfo)
      // 将页面跳转到草稿箱/教学模板
      this.typeName = '草稿箱'
      this.type = 3
      // this.changeListType('task')
      this.listType = 'task'
      // 主动刷新草稿箱
      // this.clearPackageList()
      this.clearTaskList()
      this.getTaskList()

    },
  },
  mounted () {
    this.init()
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside);
  }
}
</script>

<template>
  <JsModal
    v-loading="loading"
    :title="'选择模板'"
    @closeHandler="$modal.hide('globalCoursePackageChoiceTask')"
  >
    <div
      class="c_rl_course_package_choice_task bbox_wrappe d-flex flex-column bbox_wrapper"
    >
      <div
        class="main_wrapper d-flex flex-column"
      >
        <!-- 筛选 -->
        <div
          class="select_group d-flex align-center justify-space-between"
        >
          <div
            class="d-flex align-center"
          >
            <div
              class="origin_group d-flex align-center flex-shrink-0"
              ref="originGroup"
            >
              <div
                class="group_1 flex-row justify-between flex-shrink-0"
                @click="toggleDropdown"
              >
              <span class="text_2">
                {{ typeName }}
              </span>
                <img
                  class="thumbnail_2"
                  referrerpolicy="no-referrer"
                  src="~@/assets/images/course_package/select_course_task_search_type.png"
                />
              </div>
              <div class="dropdown-content" v-show="isDropdownVisible">
                <div
                  v-for="(option, index) in options"
                  :key="index"
                  @click="selectOption(option)"
                  class="dropdown-item"
                >
                  {{ option.name }}
                </div>
              </div>
              <input
                v-model="keyword"
                type="text"
                class="keyword"
                placeholder="搜索"
                @keydown.enter="doSearch"
              >
            </div>

            <div
              class="type_group d-flex align-center"
            >
              <div
                class="text"
                :class="{
                active: listType === 'package'
              }"
                @click="changeListType('package')"
              >
                课程
              </div>
              <div
                class="line"
              ></div>
              <div
                class="text"
                :class="{
                active: listType === 'task'
              }"
                @click="changeListType('task')"
              >
                教学模板
              </div>
            </div>
          </div>
          <div
            class="create_task"
          >
            <div
              class="create_btn"
              @click="createTask"
            >
              +&nbsp;新建任务
            </div>
          </div>

        </div>
        <!-- 导航 -->
        <div
          v-if="currentPackage"
          class="navigation_group d-flex align-center"
        >
          <div
            class="navigator"
            @click.stop="changeCurrentPackage(undefined)"
          >
            全部
          </div>
          <div
            class="symbol"
          >
            &gt;
          </div>
          <div
            class="navigator current"
          >
            {{ currentPackage.title }}
          </div>
        </div>
        <!--  内容 -->
        <div
          class="list_wrapper"
        >
          <div
            v-if="showTaskOrigin"
            class="origin_group d-flex aling-center justify-start un_sel"
          >
            <div
              class="origin"
              :class="{
                on: taskOrigin === 1
              }"
              @click.stop="changeTaskOrigin(1)"
            >
              我创建的
            </div>
            <div
              class="origin"
              :class="{
                on: taskOrigin === 2
              }"
              @click.stop="changeTaskOrigin(2)"
            >
              我领取的
            </div>
            <div
              class="origin"
              :class="{
                on: taskOrigin === 3
              }"
              @click.stop="changeTaskOrigin(3)"
            >
              我收藏的
            </div>
          </div>
          <PackageList
            v-if="showPackageList"
            :package-list="packageList"
            @changeCurrentPackage="changeCurrentPackage"
          ></PackageList>
          <TaskList
            v-if="showTaskList"
            :task-list="taskList"
            :select-list="selectList"
            :more-task="moreTask"
            :show-selected="showSelected"
            :all="all"
            :task-origin="taskOrigin"
            :show-task-origin="showTaskOrigin"
            @loadMoreTaskList="loadMoreTaskList"
            @selectTask="selectTask"
            @touchAll="touchAll"
            @changeTaskOrigin="changeTaskOrigin"
          ></TaskList>
          <!-- 25年2月14日 bug 我收藏的只显示任务 不显示其他 提出人:瑞-->
          <CollectPage
            v-if="showCollectPage"
            :switch_select_file="true"
            :show_menu="false"
            :select-list="selectList"
            :only-teach="true"
            @selectTask="selectTask"
            :show-resource-file="false"
            :show-course="false"
          ></CollectPage>
        </div>
      </div>

      <div
        class="bottom_group flex-shrink-0 d-flex align-center justify-space-between"
      >
        <div
          class="select_number_group d-flex align-center"
          :class="{
            on: showSelected
          }"
          @click.stop="changeShowSelected"
        >
          <div
            class="number"
          >
            已选: {{ selectList.length }}个
          </div>
          <div
            class="check_icon"
          ></div>
        </div>
        <div
          class="fun_group d-flex align-center"
        >
          <div
            class="btn btn_light"
            @click.stop="$modal.hide('globalCoursePackageChoiceTask')"
          >
            取消
          </div>
          <div
            class="btn btn_dark"
            @click.stop="doSure"
          >
            确定
          </div>
        </div>
      </div>
    </div>
  </JsModal>
</template>

<style scoped lang="scss">
.c_rl_course_package_choice_task {
  width: 100%;
  height: 100%;
  color: #333;
  overflow: hidden;
  background-color: #f8f8f8;
  .main_wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 20px;
    .select_group {
      width: 100%;
      //padding-bottom: 20px;
      .origin_group {
        position: relative;
        background-color: rgba(233, 233, 233, 1);
        border-radius: 4px;
        width: 300px;
        height: 30px;
        margin-right: 20px;
        .group_1 {
          background-color: rgba(212, 212, 212, 1);
          border-radius: 4px 0 0 4px;
          width: 96px;
          height: 30px;
          .text_2 {
            width: 56px;
            height: 19px;
            overflow-wrap: break-word;
            color: rgba(51, 51, 51, 1);
            font-size: 14px;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 19px;
            margin: 5px 0 0 12px;
          }
          .thumbnail_2 {
            width: 8px;
            height: 5px;
            margin: 12px 12px 0 0;
          }
        }
        //.caret {
        //  cursor: pointer;
        //  user-select: none;
        //}
        .keyword {
          width: 100%;
          line-height: 30px;
          border: none;
          outline: none;
          padding: 0 10px;
        }

        .dropdown-content {
          display: block; /* 确保内容块默认不显示，由v-show控制 */
          position: absolute; /* 根据需要调整位置 */
          background-color: #f9f9f9;
          min-width: 160px;
          box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
          z-index: 99999;
          top: 30px;
          left: 0;
        }

        .dropdown-item {
          color: black;
          padding: 12px 16px;
          text-decoration: none;
          display: block;
          cursor: pointer;
          font-size: 14px;
        }

        .dropdown-item:hover {background-color: #f1f1f1}
      }
      .type_group {
        .text {
          font-size: 14px;
          cursor: pointer;
          &.active {
            color: $main_blue;
          }
        }
        .line {
          width: 2px;
          height: 22px;
          background-color: #eee;
          margin: 0 10px;
        }
      }
      .create_task {
        .create_btn {
          font-size: 14px;
          color: $main_blue;
          cursor: pointer;
        }
      }
    }
    .navigation_group {
      width: 100%;
      padding-top: 20px;
      font-size: 14px;
      color: #666;
      line-height: 20px;
      .symbol {
        margin: 0 10px;
      }
      .navigator {
        cursor: pointer;
      }
      .current {
        color: $main_blue;
      }
    }
    .list_wrapper {
      padding: 20px 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      .origin_group {
        width: 100%;
        padding-bottom: 15px;
        padding-left: 1px;
        .origin {
          cursor: pointer;
          font-size: 14px;
          margin-right: 10px;
          color: #999;
          &.on {
            color: $main_blue;
          }
        }
      }
    }
  }
  .bottom_group {
    width: 100%;
    padding: 10px 20px;
    background-color: #fff;
    .select_number_group {
      cursor: pointer;
      .number {
        padding-right: 5px;
      }
      .check_icon {
        @include transition;
        @include background(16px 16px);
        width: 20px;
        height: 20px;
        background-image: url(~@/assets/images/course_package/choice_icon.png);
      }
      &.on {
        .check_icon {
          background-image: url(~@/assets/images/course_package/choice_icon_down.png);
        }
      }
    }
    .fun_group {
      .btn {
        margin-left:10px;
        font-size: 14px;
        line-height: 34px;
        padding: 0 20px;
        border-radius: 4px;
      }
    }
  }

}
</style>
