var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "v_fun_task_group_qr_code bbox_wrapper",
      attrs: { id: "group_qr_code" },
    },
    [
      _c("div", { staticClass: "title_wrapper" }, [
        _c("div", { staticClass: "title" }, [_vm._v("\n      二维码\n    ")]),
        _c("div", { staticClass: "close cp", on: { click: _vm.doClose } }),
      ]),
      _c("div", { staticClass: "content_wrapper" }, [
        _c("div", [
          _c("div", { staticClass: "info_wrapper d-flex align-stretch" }, [
            1
              ? _c("div", {
                  staticClass: "avatar flex-shrink-0",
                  style: {
                    backgroundImage: `url(${_vm.$avatar_url}${
                      _vm.chatDetail.chat.photo
                    }?v=${Date.now()})`,
                  },
                })
              : _vm._e(),
            0
              ? _c("img", {
                  staticClass: "avatar flex-shrink-0",
                  attrs: {
                    src: `${_vm.$avatar_url}${_vm.chatDetail.chat.photo}`,
                    alt: "",
                  },
                })
              : _vm._e(),
            _c(
              "div",
              {
                staticClass:
                  "info_group d-flex justify-space-between align-start flex-column",
              },
              [
                _c("div", { staticClass: "name" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.chatDetail.chat.chatName) +
                      "\n          "
                  ),
                ]),
                _c("div", { staticClass: "hint" }, [
                  _vm._v("\n            妙行群聊\n          "),
                ]),
              ]
            ),
          ]),
          _c("div", {
            staticClass: "qrcode",
            style: {
              backgroundImage: `url(${_vm.qrcodeBase64Url})`,
            },
          }),
        ]),
        _c("div", { staticClass: "qrcode_info" }, [
          _vm._v(
            "\n      该二维码7天内（" +
              _vm._s(_vm.sevenDaysTime) +
              "前）有效\n    "
          ),
        ]),
        _c(
          "div",
          { staticClass: "fun_wrapper d-flex align-center justify-center" },
          [
            0
              ? _c("div", { staticClass: "btn btn_light" }, [
                  _vm._v("\n        分享\n      "),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "btn btn_dark", on: { click: _vm.doSave } },
              [_vm._v("\n        保存图片\n      ")]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }