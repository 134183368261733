<!--
 * @Author       : Hugo
 * @Date         : 2020-09-07 11:59:02
 * @LastEditors  : Hugo
 * @LastEditTime : 2022-01-06 11:29:37
 * @Description  : 新建教学包
 * @FilePath     : /miaohang/src/components/FunTemplate/CreateNewPackage.vue
-->
<template>
  <div
    class="create_new_package_page"
  >
    <div class="header_group">
      <div class="title">{{page_title}}</div>
      <div class="close cp" @click="doClose();"></div>
    </div>
    <div class="content_group">
      <div class="info_group">
        <div class="info_box">
          <div
            v-if="package_data"
            class="line"
          >
            <div class="key">标题:</div>
            <input type="text" class="val title"
              @input="$api.package.doModified()"
              v-model="package_data.title"
            >
          </div>
          <div class="line">
            <div class="key">简介:</div>
            <div class="val">
              <div ref="editor"></div>
            </div>
          </div>
          <div class="line">
            <div class="key">绑定账户数量：</div>
             <div class="val price_group">
              <input type="text" class="account_num bbox"
                v-model="accountnum"
                @input="$api.package.doModified()"
              >
              <div class="text">个</div>
              <!-- <div class="hint">根据签约讲师与官方利益分成比例8:2原则，讲师可分得利益为80%，约xxx元</div> -->
            </div>
          </div>
          <div class="line"
            :style="{'align-items': 'center'}"
          >
            <div class="key">课程总价格：</div>
            <div class="val price_group">
              <!-- <input type="text" class="price"
                v-model="price"
                @input="$api.package.doModified()"
              > -->
              <div class="text flex-shrink-0">{{price}} 元</div>
              <div class="hint">根据签约讲师与官方利益分成比例8:2原则，讲师可分得利益为80%，约xxx元</div>
            </div>
          </div>
          <div class="double_line">
            <div class="unit">
              <div class="title_group">
                <div class="title">展示图：</div>
                <div class="hint">建议尺寸16:9</div>
              </div>
              <div
                class="img_group"
                :class="{active: webimg && ( webimg.preview || webimg.url)}"
                ref="img_group"
                :style="{height: `${calculate_height}px`}"
              >
                <input
                  type="file"
                  accept="image/*"
                  ref="webImg"
                  style="display:none;"
                  @change="changeImg('web')"
                >
                <img
                  :src="webimg.preview || `${$img_url}${webimg.url}`"
                  alt=""
                  class="show"
                  v-if="webimg && ( webimg.preview || webimg.url)"
                >
                <div
                  class="btn_group cp"
                  @click="$refs.webImg.click()"
                >
                  <div class="icon"></div>
                  <div class="btn">{{(webimg && ( webimg.preview || webimg.url)) ?'点击更换图片' : '点击上传图片'}}</div>
                </div>
              </div>
            </div>
            <div class="unit"
              v-if="0"
            >
              <div class="title_group">
                <div class="title">APP端展示图:</div>
                <div class="hint">建议尺寸16:9</div>
              </div>
              <div
                class="img_group"
                :class="{active: appimg && ( appimg.preview || appimg.url)}"
                ref="img_group"
                :style="{height: `${calculate_height}px`}"
              >
                <input
                  type="file"
                  accept="image/*"
                  ref="appImg"
                  style="display:none;"
                  @change="changeImg('app')"
                >
                <img
                  :src="appimg.preview || `${$img_url}${appimg.url}`"
                  alt=""
                  class="show"
                  v-if="appimg && ( appimg.preview || appimg.url)"
                >
                <div class="btn_group cp" @click="$refs.appImg.click()">
                  <div class="icon"></div>
                  <div class="btn">{{(appimg && ( appimg.preview || appimg.url)) ?'点击更换图片' : '点击上传图片'}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="template_group">
        <div class="add_group">
          <div class="add_box cp" @click.stop="touchAddTask">
            <div class="icon"></div>
            <div class="text">增加模板</div>
          </div>
          <div class="price_group" v-show="0">模板价值:200元</div>
        </div>
        <div class="list_group">
          <template
            v-if="0"
          >
            <template v-for="(item, index) in package_task_select_list">
              <div class="list"  :key="index" v-if="item.inFlag">
                <div class="info">{{item.name}}</div>
                <div class="close cp" @click="item.inFlag=false;$api.package.doModified();"></div>
              </div>
            </template>
          </template>
          <template
            v-for="(item, index) in taskList"
          >
            <div
              :key="index"
              class="list flex-column align-start d-flex"
            >
              <div class="info">
                {{ item.name }}
              </div>
              <div
                class="detail_group d-flex mt-6"
              >
                <img
                  :src="`${$avatar_url}${item.createuserthumbnail || item.updateuserthumbnail}@!small200`"
                  alt=""
                  class="avatar"
                >
              </div>
              <div
                class="close cp"
                @click="deleteTaskList({ pTask: item })"
              ></div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div
      v-if="package_data"
      class="fun_group"
    >
      <div
        class="btn send"
        :class="{on: package_data.title, cp: package_data.title}"
        @click="doSend"
      >提交审核</div>
      <button
        class="btn btn_light cp button"
        v-preventReClick
        v-if="status_share_btn"
        @click="doShare();"
      >分享课程</button>
      <div class="btn save" v-if="0">保存草稿</div>
    </div>
    <transition name="fade">
      <!-- 添加模板 -->
      <div class="mask_floor" v-show="switch_add_template">
        <div class="add_template_wrapper">
          <create-new-package-add-template :switch_add_template.sync="switch_add_template"></create-new-package-add-template>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import E from 'wangeditor';
import CreateNewPackageAddTemplate from '@/components/FunTemplate/CreateNewPackageAddTemplate.vue';
import { listForClasses, PTask, PPackage } from '@/types'
import tools from '@/api/tools'
import store from '@/store'
export default {
  components:{
    CreateNewPackageAddTemplate,
  },
  data(){
    return {
      content: '', //编辑器内容
      calculate_height: '', // 计算高度
      switch_add_template: false, // 打开添加模板
      webimg: null,
      appimg: null,
      // price: '',
      title: '',
      accountnum: 1,
      package_data: undefined
    }
  },
  computed: {
    page_title(){
      const {package_data} = this
      if (!package_data) {
        return '新建课程'
      }
      const { id } = package_data
      if (id) {
        return '编辑课程'
      }
      return '新建课程'
    },
    // package_data(){
    //   let package_data = new PPackage(this.$tools.deepClone(this.$store.state.package_data))
    //   return package_data
    // },
    packageSelectTask () {
      const { package_data } = this
      if (!package_data) {
        return {}
      }
      const { packageSelectTask } = package_data
      return packageSelectTask
    },
    taskList() {
      const { packageSelectTask } = this
      const { taskList } = packageSelectTask
      return taskList
    },
    package_task_select_list(){
      let data = this.$store.state.package_task_select_list;
      return data;
    },
    package_task_list(){
      let data = this.$tools.deepClone(this.$store.state.package_task_list);
      return data;
    },
    status_share_btn(){
      const {package_data} = this;
      if(package_data.id){
        return true;
      }
      return false;
    },
    price(){
      const {
        package_task_select_list,
        accountnum,
        taskList
      } = this
      let num = accountnum || 1
      if (!taskList || taskList.length === 0) {
        return 0
      }
      let sumTaskPrice = 0
      taskList.forEach((item) => {
        if (item.price) {
          sumTaskPrice += item.price
        }
      })
      const price = sumTaskPrice / 100 * num
      return price
      // if(!package_task_select_list || package_task_select_list.length === 0){
      //   return 0;
      // }
      // let sum_task_price = 0;
      // package_task_select_list.forEach(item => {
      //   if(item.price && item.inFlag){
      //     sum_task_price += item.price;
      //   }
      // })
      // const price = sum_task_price/100*num;
      // return price;
    }
  },
  watch:{
    // //只能输入数字 最多两位
    // price: function(val){
    //   if(val){
    //     val = String(val);
    //     this.price = val.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.');
    //     // 最多两位
    //     if(this.price.indexOf('.') != -1){
    //       if(String(this.price).split('.')[1].length > 2){
    //         this.price = String(this.price).split('.')[0]+'.' + String(this.price).split('.')[1].substring(0,2);
    //       }
    //     }
    //   }
    // },
     //只能输入数字
    accountnum: function(val){
      if(val){
        val = String(val);
        this.accountnum = val.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.');
        // // 最多两位
        // if(this.price.indexOf('.') != -1){
        //   if(String(this.price).split('.')[1].length > 2){
        //     this.price = String(this.price).split('.')[0]+'.' + String(this.price).split('.')[1].substring(0,2);
        //   }
        // }
      }
    },
    package_data: {
      handler: function(){},
      deep: true,
    },
    package_task_select_list: {
      handler: function(){},
      deep: true,
    },
    package_task_list: {
      handler: function(){},
      deep: true,
    },
  },
  async mounted(){
    const package_data = new PPackage(this.$tools.deepClone(this.$store.state.package_data))
    this.$set(this, 'package_data', package_data)
    this.$nextTick(function() {
      var editor = new E(this.$refs.editor);

      // 自定义菜单配置
      editor.config.menus = [
        'head',  // 标题
        'bold',  // 粗体
        'fontSize',  // 字号
        'fontName',  // 字体
        'italic',  // 斜体
        'underline',  // 下划线
        // 'strikeThrough',  // 删除线
        'foreColor',  // 文字颜色
        'backColor',  // 背景颜色
        // 'link',  // 插入链接
        'list',  // 列表
        'justify',  // 对齐方式
        // 'quote',  // 引用
        // 'emoticon',  // 表情
        'image',  // 插入图片
        // 'table',  // 表格
        // 'video',  // 插入视频
        // 'code',  // 插入代码
        // 'undo',  // 撤销
        // 'redo'  // 重复
      ];
      editor.config.uploadFileName = 'Filedata';
      // 限制一次最多上传 5 张图片
      editor.config.uploadImgMaxLength = 1;
      // 配置服务器端地址
      editor.config.uploadImgServer = `https://${this.$domain_name}/project/uploadwangeditor`;
      editor.config.uploadImgHeaders = {
        // "Content-Type": "multipart/form-data;",
        "loginUserCode": JSON.stringify(this.$tools.getCookie('loginUserCode')),
      }
      editor.create();
      editor.txt.html(this.package_data.content);
      this.content = this.package_data.content;
      setTimeout(()=>{

        editor.config.onchange = (html) => {
          this.content = html;
          this.$api.package.doModified();
          // // console.log(html);
        }
      },200);

      /**设置16:9 */
      let img_group = this.$refs.img_group;
      let offsetWidth = img_group.offsetWidth; //获取宽度
      // x/offsetWidth = 9 / 16
      // x = ( 9 * offsetWidth ) / 16;
      let calculate_height = ( 9 * offsetWidth) / 16;
      this.calculate_height = calculate_height;


      // 初始化图片
      this.initData();
      this.init();
    })



  },
  methods: {
    deleteTaskList(params) {
      const { pTask } = params
      const { taskList } = this
      const index = taskList.findIndex((unit) => {
        return unit.id === pTask.id
      })
      // console.log(index)
      if (index !== -1) {
        taskList.splice(index, 1)
      }
      // console.log(taskList)
    },
    touchAddTask() {
      // console.log(this.package_data)
      this.$openTeachingPackageSelectTask({
        pPackage: this.package_data,
        title: '选择模板'
      })
      // console.log(this.package_data)
      return
      this.switch_add_template = true
      // console.log(this.package_data)
    },
    doClose(){
      // const {package_modified} = this.$store.state;
      // // console.log(package_modified);
      // this.$store.commit('set_switch_package_box', false)
      this.$api.package.closeEditBox();
    },
    /**
     * @description: 更换图片(预览+上传)
     * @param {String} type | web / app
     * @return {type}
     */
    async changeImg(type){
      let file = this.$refs[`${type}Img`].files[0];
      // console.log(file);
      let preivewData = await this.previewImg(file);
      let data = {
        preview: preivewData.url,
      }
      // console.log(preivewData)
      this.$set(this, `${type}img`, data);
      // 上传
      let uploadRes = await this.uploadImg(file);
      // let picurl = uploadRes.contents.picurl;
      // let reg = /(\|\|)(\S*)/;
      // let uploadUrl = reg.exec(picurl)[2];
      // this.$set(this[`${type}img`], 'url', uploadUrl);

      // // console.log(this[`${type}img`], 'this[`${type}img`]');
      this.$set(this[`${type}img`], 'url', uploadRes);
      this.$api.package.doModified();
    },
    /** 获取预览图片地址 */
    previewImg(item){
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(item);
        reader.onload = (event) => {
          /** 地址 */
          let url = event.target.result;
          resolve({url});
          // 获取宽高
          // var image = new Image();
          // image.src = url;
          // image.onload = () => {
          //   let { width, height } = image;
          //   // // console.log(image, image.width, image.height);
          //   resolve({ url, width, height });
          // };
        };
      });
    },
    async uploadImg(item){
      const res = await this.$api.oss.uploadPicture({file: item});

      return res;


      // let formdata = new FormData();
      // formdata.append('Filedata',item);
      // let loginUserCode = this.$tools.getCookie('loginUserCode');
      // let config = {
      //   headers: {
      //     loginUserCode: JSON.stringify(loginUserCode),
      //     // loginUserCode: loginUserCode,

      //   }
      // }
      // let res = await this.$http.custom_request(`https://${this.$domain_name}/project/upLoadImg`, formdata, config);
      // return res;
    },
    /** 创建/修改教学包 */
    async doSend(){
      let {content, webimg, appimg, price, accountnum} = this;
      let {id, title} = this.package_data;
      if(!title){
        this.$toast({icon:'none',title:'提示', desc: '请输入标题', duration: 800});
        return;
      }
      let data = {
        title,
        content,
        price: price*100,
        accountnum: accountnum || 1,
        webpic: ( webimg && webimg.url ) || '',
        // apppic: ( appimg && appimg.url ) || '',
      }
      let res ;
      if(id){
        // 编辑
        data.packageId = id;
        res = await this.$http.request('/projectTeach/editPackage', data);
      }else{
        // 新建
        res = await this.$http.request('/projectTeach/addPackage', data);
      }


      /** 增加/删除 */
      await this.managerPackageTask(res.contents.PPackage.id);
      this.$router.push({
        name: 'fun_template_my',
        params: {
          type: 'created'
        }
      })

      // 如果在我创建的/付费课程下, 则增加到列表中, 否则跳转到该页面
      if (this.$route.path === 'fun_template/my/created') {
        if (store.state.pageStatus.myResource.pageTag === 'package'){
          let package_list = this.$store.state.package_list;
          if(id){
            let index = package_list.findIndex( item => {
              return item.id == id;
            })
            if(index != -1){
              package_list.splice(index,1);
              package_list.unshift(new PPackage(res.contents.PPackage));
            }
          }else{
            // 追加
            package_list.unshift(new PPackage(res.contents.PPackage));
          }
        }

      }
      this.$nextTick(() => {
        store.commit('setMyResourcePageStatus', {
          pageTag: 'package'
        })
        // 关闭窗口
        this.$store.commit('set_switch_package_box', false)

      })





    },
    /**
     * @description: 分享教学包
     * @param {*}
     * @return {*}
     */
    doShare(){
      const {package_data} = this;
      this.$api.share.doStartShare({data: package_data, type: 2});
    },
    /**初始化图片地址 */
    initData(){
      if(this.package_data){
        let {webpic, apppic, price, accountnum} = this.package_data;
        // console.log(accountnum);

        this.$set(this, 'webimg', {preview:'', url: webpic});
        this.$set(this, 'appimg', {preview:'', url: apppic});
        this.accountnum = accountnum;
        // if(price){
        //   this.price = price/100;
        // }else{
        //   this.price = '';
        // }

      }
    },

    /** 获取已选择的 教学包模板列表
     * package_task_list 用于记录原始数据
     * 提交时比较 package_task_select_list 和 package_task_list 用于增加或删除
    */
    async getPackageTaskList(){
      let packageId = this.package_data.id || '';
      if(packageId){
        let res = await this.$http.request('/projectTeach/queryPackageTaskList', {packageId})
        const { message, contents } = res
        if (message === 'success') {
          const { taskList } = contents
          const { packageSelectTask } = this
          // 赋值不能引用同一对象
          packageSelectTask.originTaskList = tools.deepClone(listForClasses({
            list: taskList,
            classes: PTask
          }))
          packageSelectTask.taskList = tools.deepClone(listForClasses({
            list: taskList,
            classes: PTask
          }))
          // console.log(packageSelectTask, this.package_data)
          return
        }
        let package_task_list = res.contents.taskList;
        this.$store.commit('set_package_task_list', package_task_list);
      }else{
        this.$store.commit('set_package_task_list', []);
      }
    },
    /**
     * 增加/删除教学包 范文
     * @param {Number} packageId 教学包id
     */
    async managerPackageTask(packageId){
      const { packageSelectTask } = this
      const addId = packageSelectTask.getAddTaskId()
      const deleteId = packageSelectTask.getDeleteTaskId()
      // console.log(addId, deleteId)
      if (addId !== '') {
        await this.$http.request('/projectTeach/managerPackageTask', {packageId, type: 1, ids: addId});
        this.$api.package.doModified();
      }
      if (deleteId !== '') {
        await this.$http.request('/projectTeach/managerPackageTask', {packageId, type: 2, ids: deleteId});
        this.$api.package.doModified();
      }
      return
      let package_task_list = this.package_task_list;
      let package_task_select_list = this.package_task_select_list;
      let add = '';
      let del = '';
      package_task_select_list.forEach( (item) => {
        if(item.inFlag){
          // 选中 判断是否已选 未选 ==> 添加
          let isIn = package_task_list.find( (subitem) => {
            return item.id == subitem.id;
          })
          if(!isIn) {
            if(add){
              add +=',';
            }
            add += item.id;
          }
        }else{
          // 未选中 判断是否已选 已选 ==> 删除
          let isIn = package_task_list.find( (subitem) => {
            return item.id == subitem.id;
          })
          if(isIn){
            if(del){
              del += ',';
            }
            del += item.id;
          }
        }
      })
      if(add){
        await this.$http.request('/projectTeach/managerPackageTask', {packageId, type: 1, ids: add});
        this.$api.package.doModified();
      }
      if(del){
        await this.$http.request('/projectTeach/managerPackageTask', {packageId, type: 2, ids: del});
        this.$api.package.doModified();
      }

    },
    async init(){
      this.$store.commit('set_package_task_select_list', null);
      this.getPackageTaskList();
      this.$store.commit('set_package_modified', false);
      // this.getPackageTaskSelectList();
    },


  }
}
</script>

<style lang="scss" scoped>
.create_new_package_page{
  @include flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  .header_group{
    @include flex;
    @include bbox;
    border-bottom: 2px solid #f4f4f4;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 80px;
    background: #FFFFFF;
    border-radius: 4px;
    .title{
      font-size: 18px;
    }
    .close{
      @include background(12px, 12px);
      position: absolute;
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/common/close.png);
      top: 50%;
      right: 40px;
      margin-top: -10px;
    }
  }
  .fun_group{
    @include flex;
    @include bbox;
    padding-left: 40px;
    width: 100%;
    height: 80px;
    background: #FFFFFF;
    border-radius: 4px;
    border-top: 2px solid #f4f4f4;
    .btn{
      width: 155px;
      line-height: 48px;
      background: #CCCCCC;
      border-radius: 4px;
      font-size: 16px;
      color: #FFFFFF;
      text-align: center;
      margin-right: 20px;
      &.save{
        background-color: rgba(0, 84, 167, 0.1);
        color: $main_blue;
      }
      &.send.on{
        background-color: $main_blue;
        color: #fff;
      }
      &.btn_light{
        background-color: #e5eef6;
        color: $main_blue;
      }
    }

  }
  .content_group{
    @include flex;
    position: relative;
    z-index: 2;
    flex: 1;
    width: 100%;
    height: 100%;
    align-items: stretch;
    overflow: hidden;
    .info_group{
      @include bbox;
      @include scrollbar;
      flex-shrink: 0;
      height:100%;
      width: 57%;
      border-right: 2px solid #f4f4f4;
      padding: 40px 0;
      .info_box{
        @include bbox;
        @include scrollbar;
        flex-shrink: 0;
        height:100%;
        width: 100%;
        overflow-y: auto;
        padding: 0 40px;
      }
      .line{
        @include flex;
        align-items: flex-start;
        margin-bottom: 20px;
        .key{
          flex-shrink: 0;
          line-height: 46px;
          font-size: 16px;
          color: #333333;
          padding-right: 20px;
        }
        .val{
          width: 100%;
          flex: 1;
          overflow: hidden;
        }
        .title{
          @include bbox;
          font-size: 16px;
          color: #333333;
          height: 46px;
          border: 1px solid #f0f0f0;
          background-color: #f4f4f4;
          border-radius: 4px;
          outline: none;
          padding: 0 10px;
          font-size: 16px;
        }
        .price_group{
          @include flex;
          .account_num{
            width: 100px;
            font-size: 16px;
            color: #333333;
            height: 46px;
            border: 1px solid #f0f0f0;
            background-color: #f4f4f4;
            border-radius: 4px;
            outline: none;
            padding: 0 10px;
            font-size: 16px;
            text-align: center;
          }
          .price{
            @include bbox;
            width: 60px;
            font-size: 16px;
            color: #333333;
            height: 46px;
            border: 1px solid #f0f0f0;
            background-color: #f4f4f4;
            border-radius: 4px;
            outline: none;
            padding: 0 10px;
            font-size: 16px;
          }
          .text{
            padding-left: 5px;
            font-size: 14px;
            color:#333;
          }
          .hint{
            font-size: 12px;
            color: #999;
            padding-left: 17px;
          }
        }
      }
      .double_line{
        @include flex;
        width: 100%;
        .unit{
          @include bbox;
          width: 50%;
          &:first-child{
            padding-right: 15px;
          }
          &:last-child{
            // padding-left: 15px;
          }
          .title_group{
            @include flexsb;
            align-items: flex-end;
            width: 100%;
            padding-bottom: 10px;
            .title{
              font-size: 16px;
              color:#333;
              line-height: 34px;
            }
            .hint{
              font-size: 14px;
              color:#999;
            }
          }
          .img_group{
            @include bbox;
            @include flex;
            width: 100%;
            height: 191px;
            background: #FBFBFB;
            border: 1px solid #F0F0F0;
            border-radius: 4px;
            justify-content: center;
            position: relative;
            overflow: hidden;
            .show{
              position: absolute;
              z-index: 2;
              width: 100%;
              height: 100%;
              top:0;
              left:0;
            }
            &.active .btn_group{
              @include transition;
              opacity: 0;
              width: 100%;
              height: 100%;
              justify-content: center;
              background-color: rgba(0,0,0,0.7);
              &:hover{
                opacity: 1;
              }
              .btn{
                color:#fff;
              }
              .icon{
                background-image: url(~@/assets/images/common/add_white.png);
              }
            }
            .btn_group{
              @include flex;
              position: relative;
              z-index: 3;
              .icon{
                @include background;
                width: 15px;
                height: 15px;
                background-image: url(~@/assets/images/common/add.png);
              }
              .btn{
                font-size: 15px;
                color: #999;
                padding-left: 6px;
              }
            }
          }
        }
      }

    }
    .template_group{
      @include bbox;
      @include scrollbar;
      @include flex;
      flex-direction: column;
      height:100%;
      width: 100%;
      flex: 1;
      padding: 40px 0;
      overflow: hidden;
      .add_group{
        @include flexsb;
        @include bbox;
        padding: 0 40px;
        flex-shrink: 0;
        width: 100%;
        padding-bottom: 20px;
        align-items: flex-end;
        .add_box{
          @include flex;
          padding:0 34px;
          height: 48px;
          background-color: $main_blue;
          border-radius: 4px;
          .icon{
            @include background;
            width: 15px;
            height: 15px;
            background-image: url(~@/assets/images/common/add_white.png);
          }
          .text{
            padding-left: 8px;
            font-size: 15px;
            color: #fff;
          }
        }
        .price_group{
          font-size: 16px;

          font-weight: 400;
          color: #0054A7;
        }
      }
      .list_group{
        @include bbox;
        @include scrollbar;
        padding: 10px 40px;
        width: 100%;
        height: 100%;
        flex: 1;
        overflow-y: auto;
        .list{
          @include bbox;
          position: relative;
          padding: 20px;
          width: 100%;
          background: #F4F4F4;
          border-radius: 4px;
          margin-bottom: 20px;
          &:last-child{
            margin-bottom: 0;
          }
          .info{
            font-size: 14px;
            line-height: 20px;
          }
          .detail_group{
            .avatar{
              width: 20px;
              height: 20px;
              border-radius: 4px;
            }
          }
          .close{
            @include background;
            position: absolute;
            width: 14px;
            height: 14px;
            top: -4px;
            right: -4px;
            background-image: url(~@/assets/images/common/clear_icon.png);
          }
        }
      }
    }
  }
  // 蒙版
  .mask_floor{
    @include flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;

  }
  // 添加模板
  .add_template_wrapper{

    width:80%;
    // width: 1160px;
    // height: 800px;
    height: 80%;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

  }
}
</style>

<style>
.create_new_package_page .w-e-toolbar{
  border-radius: 4px 4px 0 0;
  border:1px solid #f0f0f0!important;
  padding: 7px 0;
}
.create_new_package_page .w-e-text-container{
  border-radius: 0 0 4px 4px;
  border:1px solid #f0f0f0!important;
  border-top:none!important;
}
/*滚动条样式*/
.create_new_package_page .w-e-text::-webkit-scrollbar {
  width: 4px;
}
.create_new_package_page .w-e-text::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.create_new_package_page .w-e-text::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
  border-radius: 0;
  background: rgba(0, 0, 0, 0);

}
</style>
